import React from "react";
import PropTypes from "prop-types";

const FallingLeafs = ({ rootCssClasses }) => (
  <svg
    viewBox="0 0 561 272"
    width="561"
    height="272"
    className={rootCssClasses}
  >
    <g>
      <path
        d="M178.7,86.8S241,216.1,349.7,248.6s213.7-63.4,213.7-63.4S537,113.7,449.7,73.2,264.5,76.5,228.4,86.7C200.1,94.6,178.7,86.8,178.7,86.8Z"
        transform="translate(-3.7 -4.5)"
        fill="#dfc737"
        opacity="0.65"
      />
      <path
        d="M540.1,143.8c-16.1-21-44.7-50.4-90-71.4-75.5-35-158.8-7.3-203.6,7.5-7.2,2.4-13.5,4.5-18.5,5.9-27.6,7.8-48.9.2-49.1.2l-2.3-.8,1.1,2.2c.2.3,16,32.8,44.9,68.9,26.7,33.3,70.4,76.5,126.8,93.4s111.9-1.3,148.4-19.6a337.2,337.2,0,0,0,51-31.7c9.7-7.3,15.2-12.3,15.3-12.4l.5-.5-.2-.6C564.3,184.6,557.5,166.5,540.1,143.8ZM449.3,74.2c6.1,2.8,11.8,5.8,17.3,8.9h-.2c37.6,52.1,46.3,97.9,47.6,106.3a487.5,487.5,0,0,1-70.3-2.2C441.6,176,426,100.7,385.7,58A183.4,183.4,0,0,1,449.3,74.2ZM275.6,207c46.5-4,88.2-32.2,88.6-32.5h.1c10,2.9,19.9,5.4,29.7,7.4,16.5,3.4,32.3,5.7,47.1,7.3-8.7,6.5-61.9,45.4-104.3,54.1C314,234.4,293.5,221.4,275.6,207Zm-50.2-50.6c13.5-.3,27.7-3.3,37.5-5.9,5.1-1.3,10.3-2.9,14.4-4.3a32.4,32.4,0,0,0,5.7-2.3c24.9,11.1,52.1,21.8,78.9,29.8-7.3,4.7-46.2,28.6-88.5,31.5A343.3,343.3,0,0,1,225.4,156.4Zm136.7,15.3c-26.7-7.9-53.9-18.7-78.7-29.8-.1-.7-.3-2.2-.4-3.7-1.3-13-4.7-47-24.6-60.2,13.9-4.5,30.5-9.5,48.7-13.5C340.7,98,358.8,159.2,362.2,171.7Zm32.3,8.2c-9.9-2-19.9-4.6-30.1-7.5-2.5-9.2-20.4-73-54.9-108.3,22.7-4.8,47.8-7.8,73.6-6.2l-.2.2c40.3,41.3,56.2,115.9,58.7,129.1C426.8,185.5,411,183.2,394.5,179.8ZM228.6,87.6c5.1-1.4,11.3-3.5,18.6-5.9l8.9-2.9.2.2c20,12.1,23.4,46.4,24.7,59.4l.3,2.5A1017.9,1017.9,0,0,1,180.8,88.5,92.3,92.3,0,0,0,228.6,87.6Zm-46.3,4.1a1028.5,1028.5,0,0,0,98.3,51.1c-6.4,2.7-33.9,11.4-56.8,11.6A422.1,422.1,0,0,1,182.3,91.8ZM350,247.6c-3.3-1-6.5-2.1-9.7-3.2,43-10.1,95.3-48.7,102.5-54.1l.8-.6.5-.4h0a490,490,0,0,0,69.2,2.2c-5.9,6.6-32.9,35.4-74,57.7C411.8,255.5,381.1,256.9,350,247.6Zm146.9-19.4a263.9,263.9,0,0,1-50.4,19.2,291,291,0,0,0,29.2-19.4c25.4-19.1,40.1-36.4,40.3-36.7h0a354.8,354.8,0,0,0,44-4.6A330.1,330.1,0,0,1,496.9,228.2Zm65.2-43.8c-4.3.8-20.8,3.8-46.1,5-1.2-7.9-9.6-52.8-45.8-104.3a235.7,235.7,0,0,1,68.2,59.8C553.3,164.3,560.4,180.2,562.1,184.4Z"
        transform="translate(-3.7 -4.5)"
        fill="#fff"
      />
    </g>
    <g>
      <path
        d="M395.6,20.3S258.4-21.8,160.6,35.8,61.8,235.7,61.8,235.7s70.4,29.2,159.4-7.3S344.8,90.3,361.9,56.9C375.3,30.8,395.6,20.3,395.6,20.3Z"
        transform="translate(-3.7 -4.5)"
        fill="#9fa51e"
        opacity="0.65"
      />
      <path
        d="M108.1,246.7c26.3,2.5,67.4,1.5,113.5-17.4,77-31.6,113.4-111.5,132.9-154.4,3.2-6.9,5.9-12.9,8.3-17.5,13.1-25.5,33.1-36,33.3-36.1l2.2-1.1-2.3-.7c-.3-.1-34.9-10.6-81-13.9-42.6-3.1-104-.4-154.7,29.5S85.1,117.8,73.6,157a337.2,337.2,0,0,0-11.5,59c-1.2,12-1.3,19.5-1.3,19.6v.7l.6.3C61.6,236.7,79.5,244,108.1,246.7Zm112.8-19.3c-6.2,2.5-12.3,4.7-18.3,6.7v-.2C138.9,226,99.3,201.2,92.3,196.5a487.5,487.5,0,0,1,49.4-50.1c9.8,6.1,75.5,46,134.3,45.3A183.4,183.4,0,0,1,220.9,227.4ZM241.5,9.7c-28.7,36.8-36.3,86.6-36.4,87.1V97c-8.9,5.4-17.5,10.9-25.6,16.7-13.7,9.8-26.1,19.8-37.4,29.6,1.1-10.8,8.8-76.3,31.3-113.2C195.3,19.3,218.8,13,241.5,9.7ZM208.6,97.2c24-14.2,50.3-26.8,75.4-37.5l3,2.2c10.4,7.9,37.7,28.5,60.8,22.9-6.1,13.3-13.7,28.8-23.2,44.9C277.2,131.5,220,103.2,208.6,97.2Zm-1.3-1.6c1.5-8.6,10.5-53.3,37.1-86.3a343.3,343.3,0,0,1,68.4-2c-8.9,10.1-16.4,22.6-21.2,31.5-2.5,4.7-4.9,9.5-6.6,13.5a32.4,32.4,0,0,0-2.2,5.8C257.7,68.8,231.3,81.4,207.3,95.6Zm-26.7,19.7c8.2-5.9,16.9-11.5,25.9-16.9,8.4,4.5,67.4,34.7,116.7,33.3-11.9,19.9-26.8,40.4-45.5,58.2v-.2c-57.7,1.5-123.2-37.6-134.6-44.7C154.5,135.2,166.9,125.2,180.7,115.3ZM361,56.4c-2.4,4.7-5.1,10.7-8.3,17.6l-3.9,8.5h-.3c-22.5,6.5-50-14.4-60.3-22.2l-2-1.5A1018,1018,0,0,1,392.9,20.7,92.3,92.3,0,0,0,361,56.4Zm28.4-36.8A1028.5,1028.5,0,0,0,285.2,57c2.4-6.6,14.7-32.6,30.2-49.6A422.1,422.1,0,0,1,389.4,19.7Zm-228.3,17q4.4-2.6,9-4.9c-21.8,38.4-29.1,103-30,111.9v1.6h0a490,490,0,0,0-48.6,49.3c-.8-8.8-3.6-48.2,8-93.5C113.4,76.6,133.2,53.1,161.1,36.6Zm-85.6,121a263.9,263.9,0,0,1,20.2-50.1,291.1,291.1,0,0,0-5.6,34.6c-3.3,31.6-.6,54.2-.5,54.5h0A354.8,354.8,0,0,0,63,232,330,330,0,0,1,75.5,157.6ZM63.3,235.2c2.3-3.8,11.4-17.8,27.7-37.2,6.7,4.5,45.2,28.9,107.6,37.3a235.7,235.7,0,0,1-90.2,9.4C84,242.4,67.6,236.7,63.3,235.2Z"
        transform="translate(-3.7 -4.5)"
        fill="#fff"
      />
    </g>
    <g>
      <path
        d="M5.5,92.2S58.8,225.4,165,265.4s217.6-48.6,217.6-48.6S361.2,143.6,276.9,97.3s-185-9.5-221.8-1.8C26.4,101.5,5.5,92.2,5.5,92.2Z"
        transform="translate(-3.7 -4.5)"
        fill="#7aa92a"
        opacity="0.65"
      />
      <path
        d="M362.2,173.9c-14.6-22.1-41.1-53.4-84.9-77.4C204.4,56.4,119.4,78.2,73.7,89.9c-7.4,1.9-13.7,3.5-18.8,4.6-28,5.8-48.8-3.1-49-3.2l-2.2-1,.9,2.3c.1.3,13.7,33.9,40,71.8,24.4,35.1,65,81.2,120.1,101.9s111.7,6.4,149.4-9.3a337.2,337.2,0,0,0,53.1-28.1c10.1-6.6,16-11.2,16.1-11.3l.5-.4-.2-.6C383.5,216.3,378,197.8,362.2,173.9ZM276.4,98.2c5.9,3.2,11.4,6.6,16.6,10h-.2c33.9,54.6,39.4,100.9,40.2,109.4a487.5,487.5,0,0,1-70-7c-1.4-11.5-11.7-87.6-49-133A183.4,183.4,0,0,1,276.4,98.2ZM94,218.8c46.7-.8,90.2-26.1,90.7-26.4h.1c9.8,3.6,19.5,6.8,29.1,9.4,16.3,4.5,31.8,7.9,46.5,10.5-9.1,5.9-64.9,41-107.8,46.8C130.4,248.7,110.8,234.4,94,218.8Zm-46.6-54c13.5.6,27.8-1.4,37.8-3.3,5.2-1,10.4-2.2,14.7-3.3a32.4,32.4,0,0,0,5.9-1.9c24.1,12.8,50.5,25.4,76.6,35.1-7.6,4.2-48.1,25.3-90.5,25.4A343.3,343.3,0,0,1,47.4,164.8Zm135.4,24.6c-26.1-9.8-52.4-22.3-76.5-35.1-.1-.7-.1-2.2-.2-3.7-.4-13-1.4-47.2-20.4-61.7,14.2-3.5,31.1-7.4,49.5-10.1C166.4,114.5,180.3,176.8,182.7,189.5Zm31.7,10.4c-9.7-2.7-19.6-5.9-29.5-9.6-1.8-9.4-15.3-74.3-47.4-111.8,22.9-3.2,48.2-4.5,73.9-1.1l-.2.2c37.4,44,48.1,119.5,49.7,132.8C246.3,207.7,230.7,204.3,214.4,199.8ZM55.3,96.5c5.1-1.1,11.5-2.7,18.9-4.6l9-2.3.2.2c19.1,13.4,20.2,47.9,20.6,60.9,0,1,.1,1.9.1,2.5A1017.9,1017.9,0,0,1,7.5,94,92.3,92.3,0,0,0,55.3,96.5Zm-46.5.9a1028.6,1028.6,0,0,0,94.6,57.7c-6.6,2.3-34.6,9-57.5,7.6A422.1,422.1,0,0,1,8.8,97.4Zm156.6,167q-4.8-1.8-9.5-3.9c43.6-7.1,98.4-42,105.9-46.9l.9-.6.5-.3h0a490,490,0,0,0,68.9,6.9c-6.3,6.2-35.3,33.1-77.8,52.5C226.5,276.5,195.7,275.8,165.4,264.4Zm147.9-9.3a263.9,263.9,0,0,1-51.6,15.7,291,291,0,0,0,30.4-17.3c26.7-17.3,42.5-33.6,42.7-33.8h0a354.7,354.7,0,0,0,44.2-1.5A330,330,0,0,1,313.3,255.1Zm68-39.2c-4.4.5-21,2.3-46.3,1.8-.7-8-5.9-53.3-38.5-107.2a235.7,235.7,0,0,1,64,64.3C374,195.2,379.9,211.6,381.3,215.9Z"
        transform="translate(-3.7 -4.5)"
        fill="#fff"
      />
    </g>
  </svg>
);

FallingLeafs.propTypes = {
  rootCssClasses: PropTypes.string,
};

FallingLeafs.defaultProps = {
  rootCssClasses: "",
};

export default FallingLeafs;
